import React from 'react';
import { Typography, Grid } from '@mui/material';

const Display = ({ title, loading, error, centerTop, center, rightTop, right }) => {
  return (
    <>
      <Typography variant="h5" align="center" style={{ marginTop: '10px' }}>
        {title}
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={10} lg={8}>
          {loading ? (
            <p>Cargando...</p>
          ) : error ? (
            <Typography variant="h6">{error}</Typography>
          ) : (
            <>
              {centerTop} {/** ShowQuestion */}
              {center}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Display;