import React, { useState, useEffect } from "react";
import { MessageBox } from "react-chat-elements";

const Message = ({ text, isUser, num_msgs, max_msgs, img_logo, colors }) => {
  const [isEnglish, setIsEnglish] = useState(false);

  useEffect(() => {
    const browserLang = navigator.language || navigator.languages[0];
    if (browserLang.startsWith("en")) {
      setIsEnglish(true);
    }
  }, []);

  return (
    <>
      {isUser ? (
        <MessageBox
          // how do i set text size in 24px?
          // answer: add a className to the MessageBox component
          // 
          className="items-end font-sans"
          position={"right"}
          type={"text"}
          title={
            <p
              className={`font-bold m-1 ${colors ? colors.userTextColor : ""}`}
            >
              {isEnglish ? "You" : "Tú"}
            </p>
          }
          dateString={num_msgs + "/" + max_msgs}
          date
          avatar={"https://cdn-icons-png.flaticon.com/512/2102/2102647.png"}
          text={text}
        />
      ) : (
        <MessageBox
          className="font-sans"
          position={"left"}
          type={"text"}
          title={
            <p
              className={`font-bold m-1 ${
                colors ? colors.assistantTextColor : ""
              }`}
            >
              {isEnglish ? "Assistant" : "Asistente"}
            </p>
          }
          avatar={img_logo}
          text={text}
        />
      )}
    </>
  );
};

export default Message;
