import api from '../utils/api';

export const getRetrievals = async () => {
  const { data } = await api.get('content/retrievals/');
  return data;
};

export const getRetrieval = async (id) => {
  const { data } = await api.get(`content/retrievals/${id}/`);
  return data;
};

const RetrievalsApi = {
  getRetrievals,
  getRetrieval,
};

export default RetrievalsApi;
