import React from "react";
// import { Divider, Typography, Button } from '@mui/material';
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import logo from "../img/logo-cognitive.png";
import logoPyxisSVG from "../img/pyxislogo.svg";

const Header = (course) => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <div className="mb-5">
      <div className="flex flex-row mx-4 place-content-center">
        <img
          src={logo}
          style={
            isDesktop
              ? {
                  maxWidth: "15%",
                  height: "auto",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  margin: "auto",
                }
              : // on the left, not centered
                {
                  maxWidth: "45%",
                  height: "auto",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  margin: "auto",
                  float: "left",
                }
          }
          alt="Cognitive Solutions"
          onClick={() => navigate(`/`)}
        />
        <img
          className="mt-4"
          src={logoPyxisSVG}
          style={
            isDesktop
              ? { maxWidth: "7%", height: "7%" }
              : { maxWidth: "24%", height: "24%" }
          }
          alt="Pyxis"
        />
      </div>
      <Divider variant="middle" />
    </div>
  );
};

export default Header;
