import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "react-chat-elements/dist/main.css"
import MainRouter from './containers/MainRouter';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: sessionStorage.getItem('theme') === 'dark' ? 'dark' : 'light',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <MainRouter />
    </ThemeProvider>
  </React.StrictMode>
);