const retrieval = {
  retrievalId: process.env.REACT_APP_RETRIEVAL,
};

const componentColors = {
  changeTopic: {
    backgroundColor: "#1463B2",
    textColor: "white",
    borderColor: "#1463B2",
    hoverColor: "#00008b",
  },
  matrixMode: {
    backgroundColor: "#006400",
    textColor: "white",
    borderColor: "#008000",
    hoverColor: "#008000",
  },
  feedback: {
    backgroundColor: "#1463B2",
    textColor: "white",
    borderColor: "#1463B2",
    hoverColor: "#00008b",
  },
  like: {
    backgroundColor: "#1463B2",
    textColor: "white",
    borderColor: "#1463B2",
    hoverColor: "#228b22",
  },
  dislike: {
    backgroundColor: "#1463B2",
    textColor: "white",
    borderColor: "#1463B2",
    hoverColor: "#B20000",
  },
  outline: {
    backgroundColor: "white",
    textColor: "#1463B2",
    borderColor: "#1463B2",
    hoverColor: "#f2f2f2",
  },
  arrowColor: "#1463B2",
};

const parameters = {
  componentColors,
  retrieval,
};

export default parameters;
