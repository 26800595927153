import api from "../utils/api";

export const addQuestion = async (question, retrieval, nodeId) => {
  const endpoint = nodeId
    ? `content/retrievals/${retrieval}/nodes/${nodeId}/chats/`
    : `content/retrievals/${retrieval}/chats/`;
  const { data } = await api.post(endpoint, { question: question });
  return data.id;
};

export const addQuestionRetData = async (question, retrieval, nodeId) => {
  const endpoint = nodeId
    ? `content/retrievals/${retrieval}/nodes/${nodeId}/chats/`
    : `content/retrievals/${retrieval}/chats/`;
  const resp = await api.post(endpoint, { question: question });
  return resp;
};

export const getChat = async (id) => {
  const { data } = await api.get(`content/chats/${id}/`);
  return data;
};

export const getChats = async (retrieval) => {
  const { data } = await api.get(`content/retrievals/${retrieval}/chats/`);
  return data;
};

export const getRelatedChats = async (id) => {
  const { data } = await api.get(`content/chats/${id}/similar/`);
  return data;
};

export const addFeedback = async (chat, like, comment) => {
  const endpoint = `content/chats/${chat}/`;
  if (comment === "") {
    const { data } = await api.put(endpoint, { like: like });
    return data;
  }
  const { data } = await api.put(endpoint, { like: like, comment: comment });
  return data;
};

export const addFeedback2 = async (chat, comment) => {
  const endpoint = `content/chats/${chat}/`;
  const { data } = await api.put(endpoint, { comment: comment });
  return data;
};

export const matrixMode = async (chat, matrixMode) => {
  const endpoint = `content/chats/${chat}/`;
  const { data } = await api.put(endpoint, { matrix_mode: matrixMode });
  return data;
};

const QuestionsApi = {
  addQuestion,
  getChat,
  getChats,
  getRelatedChats,
  addFeedback,
  addFeedback2,
  matrixMode,
};

export default QuestionsApi;
