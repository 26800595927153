import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Markdown from "react-markdown";
import {
  IconButton,
  OutlinedInput,
  InputAdornment,
  Link,
  Chip,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Message from "./Message";
import Feedback from "./Feedback";
import { Button } from "@mui/material";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import linkify from "./Utils";
import "./ShowQuestionApiCognitive.css";

// Initial values
const MAX_MSG_LENGTH = 1000;
const MAX_RESPONSES = 10;
const MAX_RESP_STATUS = 300;

const ShowQuestion = (props) => {
  const [isEnglish, setIsEnglish] = useState(false);
  const [question, setQuestion] = useState(props.chat ? props.chat : null);
  const [messages, setMessages] = useState(
    props.chat
      ? props.chat.messages.map((msg) => ({ ...msg, attachment: null }))
      : []
  );
  const [ended, setEnded] = useState(props.chat ? props.chat.ended : false);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [iniMessage, setIniMessage] = useState(null);
  const [maxMsgLength, setMaxMsgLength] = useState(MAX_MSG_LENGTH);
  const [maxResponses, setIMaxResponses] = useState(MAX_RESPONSES);
  const [cantResponses, setcantResponses] = useState(1);
  const [endedStatus, setEndedStatus] = useState(0);
  const [topic, setTopic] = useState("");
  const [activeMatrix, setActiveMatrix] = useState(false);
  const [matrixModal, setMatrixModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [allowAsk, setAllowAsk] = useState(false);

  const [messageFile, setMessageFile] = useState(null);
  const [attachments, setAttachments] = useState([]);

  const messagesEndRef = useRef(null);
  const navigate = useNavigate();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "end",
    });
  };

  useEffect(() => {
    const browserLang = navigator.language || navigator.languages[0];
    if (browserLang.startsWith("en")) {
      setIsEnglish(true);
    }

    if (!props.chat) {
      const getInfoRetrieval = async (retrievalId) => {
        try {
          const data = await props.getRetrieval(retrievalId);
          setIniMessage(data.initial_message);
          setMaxMsgLength(data.max_msg_length);
          setIMaxResponses(data.max_responses);
          setAllowAsk(true);
        } catch (error) {
          showStatusModal();
          console.log(error);
        }
      };
      getInfoRetrieval(props.retrievalId);
    } else {
      setTopic(props.chat.topic);
      setAllowAsk(true);
    }
    scrollToBottom();
  }, [messages, props]);

  const requestStatus = (status) => {
    if (status > MAX_RESP_STATUS) {
      setEndedStatus(status);
    }
  };

  ////////// POPUP ERROR /////////////
  const showStatusModal = () => {
    setStatusModal(true);
    setEnded(true);
    setTimeout(() => {
      setStatusModal(false);
    }, 5000);
  };

  ////////// MATRIX MODE /////////////
  const openMatrixModal = () => {
    setMatrixModal(true);
  };

  const executeMatrixMode = async () => {
    setMatrixModal(false);
    try {
      const response = await props.addMatrixMode(
        question.id,
        props.matrix_mode
      );
      console.log(response);
      setActiveMatrix(true);
      setMessages(response.data.messages);
      setQuestion(response.data);
      setMaxMsgLength(response.data.max_msg_length);
    } catch (error) {
      showStatusModal();
      console.error("Error:", error);
    }
  };

  //////////// REFERENCES ////////////////

  const getUsedBlocks = (message) => {
    let usedBlocksSet = new Set();
    // The references can appear in the answer in the form of [1,2,3] or [1][2][3]
    let regex = /\[((\d+)(,( )*\d+)*)\]/g;
    let match = regex.exec(message.content);
    while (match != null) {
      let blocks = match[1].split(",");
      blocks.forEach((block) => usedBlocksSet.add(+block));
      match = regex.exec(message.content);
    }
    let usedBlocks = Array.from(usedBlocksSet); //new
    return usedBlocks;
  };

  // Source is a text with this content: "https://www.youtube.com/watch?v=... (desde x hasta y)"
  // Returns only the url (until the first space)
  const getUrlSource = (source) => {
    return source.split(" ")[0];
  };

  // Source is a text with this content: "https://www.youtube.com/watch?v=... (desde x hasta y)"
  // Returns only the rest of the text (after the first space)
  const getRestSource = (source) => {
    return source.split(" ").slice(1).join(" ");
  };

  /////////////// FUNCTION TO SEND MESSAGES //////////////
  const sendMessage = async () => {
    if (inputValue.trim() === "") return;
    setEndedStatus(0);

    // USER MESSAGE
    const userMessage = {
      content: inputValue,
      role: "user",
      usr_msg_num: cantResponses,
      attachment: messageFile,
    };

    if (messageFile) {
      setAttachments((prev) => [
        ...prev,
        { messageIndex: messages.length, file: messageFile },
      ]);
    }

    setcantResponses(cantResponses + 1);
    setMessages([...messages, userMessage]);
    setInputValue("");
    setIsLoading(true);

    // LOADING MESSAGE
    const loadingMessage = {
      content: "Pensando...",
      role: "assistant",
      loading: true,
    };
    if (isEnglish) {
      loadingMessage.content = "Thinking...";
    }

    setMessages((prevMessages) => [...prevMessages, loadingMessage]);

    /// send information to API and update messages and state
    try {
      // API call
      let response = null;
      if (question != null) {
        response = await props.addMessage(inputValue, question.id, messageFile);
      } else {
        response = await props.addQuestionRetData(
          inputValue,
          props.retrievalId,
          messageFile
        );
      }
      setMessageFile(null);
      // see if errors
      if (response.status) {
        requestStatus(response.status);
        if (endedStatus > MAX_RESP_STATUS) {
          setEnded(true);
          showStatusModal();
        } else {
          setEnded(response.data.ended);
        }
      }
      // set Question chat
      setQuestion(response.data);

      // Update messages with attachment
      const updatedMessages = response.data.messages.map((msg, index) => {
        if (index < messages.length && messages[index].attachment) {
          return {
            ...msg,
            attachment: messages[index].attachment,
          };
        }
        return msg;
      });

      // set list of messages
      if (iniMessage || iniMessage === "") {
        setMessages(updatedMessages.slice(1)); // Exclude initial message
      } else {
        setMessages(updatedMessages);
      }
    } catch (error) {
      console.error("Error:", error);
      showStatusModal();
    } finally {
      setIsLoading(false);
      setMessageFile(null);
    }
  };

  //////////////// RETURN FUNCTION //////////////////////

  return (
    <div
      className="flex flex-col items-start w-full mb-10 space-y-8"
      style={{ minHeight: "50px" }}
    >
      {topic ? <Chip label={topic} variant="outlined" /> : null}

      <div
        className="w-full p-4 space-y-3 rounded shadow-inner bg-slate-200 shadow-slate-400"
        style={{ minHeight: "600px" }}
      >
        {iniMessage != null && iniMessage !== "" && (
          ////////////////// show initial message outside list of messages ///////////////////////
          <Message
            isUser={false}
            text={
              <div className="text-base font-medium md:text-md">
                {linkify(iniMessage)}
              </div>
            }
            img_logo={props.logo}
            colors={props.colors}
          />
        )}

        {messages.map((message, index) =>
          ////////////////// iterate in list of messages  ///////////////////////
          message.role === "user" ? (
            ////// User message //////
            <Message
              key={index}
              isUser={true}
              num_msgs={message.usr_msg_num}
              max_msgs={maxResponses}
              colors={props.colors}
              text={
                <>
                  <div
                    className="text-base md:text-md"
                    dangerouslySetInnerHTML={{
                      __html: linkify(message.content.replace(/\n/g, "<br />")),
                    }}
                  />
                  {attachments.find((att) => att.messageIndex === index) && (
                    <div className="flex items-center mt-2 text-xs text-gray-500">
                      <AttachFileIcon fontSize="small" />
                      <a
                        href={URL.createObjectURL(
                          attachments.find((att) => att.messageIndex === index)
                            .file
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-1 underline"
                      >
                        {
                          attachments.find((att) => att.messageIndex === index)
                            .file.name
                        }
                      </a>
                    </div>
                  )}
                </>
              }
            />
          ) : ////// Assistant message //////
          index > 0 ? (
            ///// Assistant message, but not first one //////
            <Message
              key={index}
              isUser={false}
              img_logo={props.logo}
              colors={props.colors}
              text={
                message.loading ? (
                  ///// Assistant message, loading//////
                  message.content
                ) : (
                  ///// Assistant message, from messages //////
                  <div>
                    <div className="text-justify whitespace-pre-line">
                      <Markdown className="text-base font-medium md:text-md">
                        {message.content.replace(/\\+/g, "\\")}
                      </Markdown>
                    </div>
                    {getUsedBlocks(message).length > 0 && (
                      <h4>{isEnglish ? "References:" : "Referencias:"}</h4>
                    )}
                    {messages[index].references.map((block, index2) => {
                      /////// Show references /////////
                      if (block.used) {
                        return (
                          <p key={index2}>
                            {"[" + block.reference_num + "] "}
                            {block.source.includes("https") ? (
                              <>
                                <a
                                  href={getUrlSource(block.source)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {getUrlSource(block.source)}
                                </a>{" "}
                                <i>{getRestSource(block.source)}</i>
                              </>
                            ) : (
                              block.source
                            )}
                          </p>
                        );
                      } else {
                        return null;
                      }
                    })}
                    {props.otherRefs && (
                      /////// Show other references /////////
                      <>
                        <h4>
                          {isEnglish
                            ? "Other related references:"
                            : "Otras referencias relacionadas:"}
                        </h4>
                        {messages[index].references.map((block, index2) => {
                          if (!block.used) {
                            return (
                              <p key={index2}>
                                {"[" + block.reference_num + "] "}
                                {block.source.includes("https") ? (
                                  <a
                                    href={block.source}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {block.source}
                                  </a>
                                ) : (
                                  block.source
                                )}
                              </p>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </>
                    )}
                  </div>
                )
              }
            />
          ) : (
            <></>
          )
        )}
      </div>
      <div ref={messagesEndRef} className="h-2" />
      <div
        className={`sticky place-self-center bottom-2 ${
          activeMatrix ? "bg-green-100" : "bg-white"
        } shadow-md w-fit md:w-full p-2 border-solid border rounded-lg border-slate-200 m-2`}
      >
        {/*////////////////// GRILLA INICIAL DE 12  ///////////////////////*/}
        <div className="grid grid-cols-12">
          {/*////////////////// ESPACIO DE BOTONES EN GRILLA INICIAL  ///////////////////////*/}
          <div
            className={`col-span-12 ${
              props.matrix_mode
                ? "sm:col-span-4 md:col-span-3 mx-1"
                : "sm:col-span-3 md:col-span-2 mx-2"
            }`}
          >
            {/*////////////////// GRILLA INTERNA DE BOTONES  ///////////////////////*/}
            <div
              className={`${
                props.matrix_mode ? "grid grid-cols-2" : "col-span-1"
              } gap-1`}
            >
              {/*////////////////// BOTON CAMBIAR TEMA  ///////////////////////*/}
              <div className="col-span-1 mx-0 sm:mx-1">
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    borderRadius: "10px",
                    height: "10px",
                    paddingY: "22px",
                    paddingX: "40px",
                    alignItems: "center",
                    alignSelf: "center",
                    marginLeft: "0px",
                    marginRight: "0px",
                    textTransform: "none",
                    width: "100%",
                    backgroundColor: props.colors
                      ? props.colors.changeTopic.backgroundColor
                      : "#1463B2",
                    color: props.colors
                      ? props.colors.changeTopic.textColor
                      : "#fff",
                    ":hover": {
                      bgcolor: props.colors
                        ? props.colors.changeTopic.hoverColor
                        : "#2196f3",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    if (props.chat) {
                      navigate(`/projects/${question.assistant}/`);
                    } else {
                      navigate(0);
                    }
                  }}
                >
                  {isEnglish ? (
                    <p className="font-sans text-xs">Change topic</p>
                  ) : (
                    <p className="font-sans text-xs">Cambiar tema</p>
                  )}
                  <AutoStoriesIcon sx={{ ml: 1.5 }} />
                </Button>
              </div>
              {/*////////////////// BOTON MATRIX MODE  ///////////////////////*/}
              <div className="col-span-1 mx-2">
                {props.matrix_mode && (
                  ////////////////// show self-improvement button ///////////////////////
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      borderRadius: "10px",
                      height: "10px",
                      width: "100%",
                      paddingY: "22px",
                      paddingX: "10px",
                      alignItems: "tight",
                      alignSelf: "center",
                      textTransform: "none",
                      backgroundColor: props.colors
                        ? props.colors.matrixMode.backgroundColor
                        : "#1463B2",
                      color: props.colors
                        ? props.colors.matrixMode.textColor
                        : "#fff",
                      ":hover": {
                        bgcolor: props.colors
                          ? props.colors.matrixMode.hoverColor
                          : "#2196f3",
                        color: "white",
                      },
                    }}
                    onClick={openMatrixModal}
                  >
                    {isEnglish ? (
                      <p className="font-sans text-xs">S I M</p>
                    ) : (
                      <p className="font-sans text-xs">S I M</p>
                    )}
                  </Button>
                )}
              </div>
            </div>
          </div>
          {/*////////////////// ESPACIO DE PROMPT EN GRILLA INICIAL  ///////////////////////*/}
          <div
            className={`${
              props.matrix_mode
                ? "sm:col-span-5 md:col-span-7"
                : "sm:col-span-6 md:col-span-8"
            } col-span-12`}
          >
            {/*////////////////// GRILLA INTERNA DE PROMPT  ///////////////////////*/}
            <div className="grid grid-cols-12">
              {/*////////////////// ESPACIO DE PROMPT  ///////////////////////*/}
              <div className="col-span-11 my-3 sm:my-0">
                {
                  maxResponses > cantResponses && ended === false ? (
                    ////////////////// show user prompt ///////////////////////
                    <>
                      <div className="flex flex-row">
                        {props.allow_files && (
                          <div
                            className="mx-1 rounded-lg shadow-md cursor-pointer bg-slate-200 hover:bg-slate-100"
                            onClick={() => {
                              document.getElementById("file-input").click();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-6 mt-2.5 mx-2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
                              />
                            </svg>
                          </div>
                        )}
                        <input
                          type="file"
                          id="file-input"
                          accept=".jpg"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              setMessageFile(file);
                              console.log(file);
                            }
                          }}
                        />
                        <OutlinedInput
                          className="w-full bg-white min-h-11 placeholder-shown"
                          size="small"
                          multiline
                          error={inputValue.length > maxMsgLength}
                          type="text"
                          placeholder={
                            isEnglish
                              ? "Type your question here..."
                              : "Escribe tu pregunta aquí..."
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <p
                                className={
                                  "text-xs " +
                                  (inputValue.length > maxMsgLength
                                    ? "text-red-500"
                                    : "text-slate-500")
                                }
                              >
                                {inputValue.length}/{maxMsgLength}
                              </p>
                            </InputAdornment>
                          }
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && e.shiftKey) {
                              setInputValue(inputValue + "\n");
                            } else if (e.key === "Enter") {
                              if (messageFile) {
                                sendMessage(inputValue, messageFile);
                              } else {
                                sendMessage(inputValue);
                              }
                              setInputValue("");
                            }
                          }}
                          disabled={isLoading || allowAsk === false} // Add this line to disable the input field while thinking
                        />
                      </div>
                    </>
                  ) : (
                    <div className="flex items-center w-screen px-3 text-sm border-0 text-slate-500">
                      {ended &&
                      //////////////////////////////////////////////////////
                      ////////////////// ENDED IN PROMPT////////////////////
                      //////////////////////////////////////////////////////
                      endedStatus <= MAX_RESP_STATUS &&
                      activeMatrix === false ? (
                        <p className="mr-1">
                          {isEnglish
                            ? "We should have ended this conversation."
                            : "Debimos finalizar esta conversación."}
                        </p>
                      ) : ended && activeMatrix === true ? (
                        <p className="mr-1">
                          {isEnglish
                            ? "Self-Improvement Mode finished."
                            : "Modo de Self-Improvement finalizado."}
                        </p>
                      ) : endedStatus > MAX_RESP_STATUS ? (
                        <p className="mr-1">
                          {isEnglish
                            ? "System Error. Please try again."
                            : "Error del sistema. Por favor, inténtalo nuevamente."}
                        </p>
                      ) : (
                        <p className="mr-1">
                          {isEnglish
                            ? "You have reached the maximum of questions!"
                            : "¡Alcanzaste el máximo de preguntas!"}
                        </p>
                      )}
                      <Link
                        component="button"
                        variant="inherit"
                        onClick={() => {
                          if (props.chat) {
                            navigate(`/projects/${question.assistant}/`);
                          } else {
                            navigate(0);
                          }
                        }}
                      >
                        {isEnglish
                          ? "Start a new chat"
                          : "Inicia un nuevo chat"}
                      </Link>
                    </div>
                  )
                  //////////////////////////////////////////////////////
                  ////////////////// END: ENDED IN PROMPT///////////////
                  //////////////////////////////////////////////////////
                }
              </div>
              {/*////////////////// ESPACIO DE BOTON  ///////////////////////*/}
              <div className="col-span-1 my-3 sm:my-0">
                <IconButton
                  size="large"
                  className="bg-white"
                  color="black"
                  disabled={
                    isLoading ||
                    inputValue.length > maxMsgLength ||
                    maxResponses * 2 === messages.length ||
                    allowAsk === false
                  }
                  onClick={() => {
                    sendMessage(inputValue);
                    setInputValue("");
                  }}
                  text="Send"
                >
                  <SendIcon />
                </IconButton>
              </div>
            </div>
          </div>
          {/*////////////////// ESPACIO DE FEEDBACK EN GRILLA INICIAL  ///////////////////////*/}
          <div className="col-span-12 my-1 sm:col-span-3 md:col-span-2">
            <Feedback
              colors={props.colors}
              id={question ? question.id : -1}
              addFeedback={props.addFeedback}
              addFeedback2={props.addFeedback2}
            />
          </div>
        </div>
      </div>

      {matrixModal && (
        ///////////////////////////////////////////////////////
        ////////////////// POPUP MATRIX MODE //////////////////
        //////////////////////////////////////////////////////

        <div className="fixed inset-0 z-100">
          <div className="items-end justify-center px-4 pt-4 pb-20 mt-40 text-center">
            <span
              className="hidden sm:inline-block sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block w-3/4 px-8 pt-6 mt-0 overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow lg:mt-20 sm:align-middle xs:w-3/4 lg:w-1/2">
              <div className="w-full mb-6">
                <h2 className="text-2xl font-semibold">
                  Self-improvement Mode
                </h2>
                {isEnglish ? (
                  <p className="mt-2 mb-2 text-lg">
                    Do you want to activate Self-improvement Mode? By activating
                    this mode, the system will allow you to enter the
                    corrections you want to make.
                  </p>
                ) : (
                  <p className="mt-2 mb-2 text-lg">
                    Desea activar el modo de auto aprendizaje? Al activar este
                    modo, el sistema le permitirá ingresar las correcciones que
                    desee realizar.
                  </p>
                )}
                <div className="flex flex-row">
                  <span className="right-0 mx-auto mr-0 origin-bottom-right">
                    <Button
                      className="w-min"
                      variant="contained"
                      disabled={activeMatrix}
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        height: "10px",
                        width: "min-content",
                        paddingY: "22px",
                        paddingX: "10px",
                        alignItems: "center",
                        alignSelf: "center",
                        marginLeft: "0px",
                        marginRight: "10px",
                        textTransform: "none",
                        backgroundColor: "#03A062",
                        color: "#fff",
                      }}
                      onClick={() => setMatrixModal(false)}
                    >
                      {isEnglish ? (
                        <p className="font-sans text-xs">Cancel</p>
                      ) : (
                        <p className="font-sans text-xs">Cancelar</p>
                      )}
                    </Button>
                    <Button
                      className="w-min"
                      variant="contained"
                      disabled={activeMatrix}
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        height: "10px",
                        width: "min-content",
                        paddingY: "22px",
                        paddingX: "10px",
                        alignItems: "center",
                        alignSelf: "center",
                        marginLeft: "0px",
                        marginRight: "0px",
                        textTransform: "none",
                        backgroundColor: "#03A062 ",
                        color: "#fff",
                      }}
                      onClick={executeMatrixMode}
                    >
                      {isEnglish ? (
                        <p className="font-sans text-xs">Start</p>
                      ) : (
                        <p className="font-sans text-xs">Iniciar</p>
                      )}
                    </Button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        ////////////// END POPUP MATRIX MODE  ///////////////
      )}

      {statusModal && (
        //////////////////////////////////////////////////////
        ////////////////// POPUP ERROR ///////////////////////
        //////////////////////////////////////////////////////

        <div className="fixed inset-0 z-100">
          <div className="items-end justify-center px-4 pt-4 pb-20 m-40 text-center sm:block sm:p-0">
            <div className="inline-block px-8 pt-6 mt-20 overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow sm:align-middle">
              <div className="w-full mb-6">
                {isEnglish ? (
                  <p className="mt-2 mb-2 text-lg text-gray-500">
                    There has been a system error, please try again.
                  </p>
                ) : (
                  <p className="mt-2 mb-2 text-lg text-gray-500">
                    Ha habido un error del sistema, por favor inténtelo
                    nuevamente.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        ////////////////// END POPUP ERRORS //////////////////
      )}
    </div>
  );
};

export default ShowQuestion;
