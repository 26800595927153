import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import QuestionWithRetrieval from '../QuestionWithRetrieval';
import Header from '../../components/Header';

const MainRouter = () => {

  return (
    <BrowserRouter>
     <Header />
      <Routes>
        <Route index element={<QuestionWithRetrieval />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRouter;
