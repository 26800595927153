import ShowQuestion from "../submodule/src/App";

import Display from "../components/Display";

import { getRetrieval } from "../api/RetrievalsApi";
import {
  addQuestionRetData,
  addFeedback,
  addFeedback2,
} from "../api/QuestionsApi";

import { addMessage } from "../api/MessagesApi";

import parameters from "../utils/parameters";
import logo from "../pyxis.png";

const QuestionWithRetrieval = () => {
  const otherReferences = false;

  return (
    <Display
      loading={false}
      error={null}
      centerTop={
        <ShowQuestion
          getRetrieval={getRetrieval}
          retrievalId={parameters.retrieval.retrievalId}
          addQuestionRetData={addQuestionRetData}
          addMessage={addMessage}
          logo={logo}
          otherRefs={otherReferences}
          addFeedback={addFeedback}
          addFeedback2={addFeedback2}
          newApi={false}
          colors={parameters.componentColors}
        />
      }
    />
  );
};

export default QuestionWithRetrieval;
