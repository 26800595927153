import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  OutlinedInput,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import CommentIcon from "@mui/icons-material/Comment";

const Feedback = (props) => {
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [like, setLike] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showConfirmationDialog2, setShowConfirmationDialog2] = useState(false);
  const [comment, setComment] = useState("");

  const [isEnglish, setIsEnglish] = useState(false);

  useEffect(() => {
    const browserLang = navigator.language || navigator.languages[0];
    if (browserLang.startsWith("en")) {
      setIsEnglish(true);
    }
  }, []);

  const handleLike = () => {
    setLiked(true);
    setDisliked(false);
    setLike(true);
    setShowConfirmationDialog(true);
  };

  const handleDislike = () => {
    setLiked(false);
    setDisliked(true);
    setLike(false);
    setShowConfirmationDialog(true);
  };

  const handleComment = () => {
    setShowConfirmationDialog2(true);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const sendFeedback = async (id) => {
    if (id === -1) {
      console.log("Es necesario preguntar antes de dar feedback");
      closeWindow();
      return;
    }
    try {
      await props.addFeedback(id, like, comment);
      console.log("Feedback enviado");
    } catch {
      console.error("Error al enviar feedback:");
    }

    closeWindow();
  };

  const sendFeedback2 = async (id) => {
    if (id === -1) {
      console.log("Es necesario preguntar antes de dar feedback");
      closeWindow();
      return;
    }
    try {
      await props.addFeedback2(id, comment);
      console.log("Feedback enviado");
    } catch {
      console.error("Error al enviar feedback:");
    }

    closeWindow();
  };

  const closeWindow = () => {
    setShowConfirmationDialog(false);
    setShowConfirmationDialog2(false);
  };

  const closeWindowAndSendFeedback = () => {
    sendFeedback(props.id);
    setShowConfirmationDialog(false);
  };

  const closeWindowAndSendFeedback2 = () => {
    sendFeedback2(props.id);
    setShowConfirmationDialog2(false);
  };

  const likeStyle = {
    borderRadius: "10px",
    width: "20px",
    height: "20px",
    padding: "20px",
    textTransform: "none",
    backgroundColor: props.colors
      ? props.colors.like.backgroundColor
      : "#1463B2",
    color: "#fff",
    marginRight: "8px",
    fontSize: 15,
    ":hover": {
      bgcolor: props.colors ? props.colors.like.hoverColor : "#2196f3",
      color: "white",
    },
  };

  const dislikeStyle = {
    borderRadius: "10px",
    width: "20px",
    height: "20px",
    padding: "20px",
    textTransform: "none",
    backgroundColor: props.colors
      ? props.colors.dislike.backgroundColor
      : "#1463B2",
    color: "#fff",
    marginRight: "8px",
    fontSize: 15,
    ":hover": {
      bgcolor: props.colors ? props.colors.dislike.hoverColor : "#2196f3",
      color: "white",
    },
  };

  const feedbackStyle = {
    borderRadius: "10px",
    width: "20px",
    height: "20px",
    padding: "20px",
    textTransform: "none",
    backgroundColor: props.colors
      ? props.colors.feedback.backgroundColor
      : "#1463B2",
    color: "#fff",
    marginRight: "8px",
    fontSize: 15,
    ":hover": {
      bgcolor: props.colors ? props.colors.feedback.hoverColor : "#2196f3",
      color: "white",
    },
  };

  const iconStyle = {
    color:
      liked && props.colors ? props.colors.like.backgroundColor : "inherit",
  };

  const iconStyle2 = {
    color:
      disliked && props.colors ? props.colors.like.backgroundColor : "inherit",
  };

  return (
    <div>
      <div className="flex flex-row justify-end">
        <span className="xl:mr-5 mr-0">
          <IconButton
            variant="contained"
            size="small"
            sx={feedbackStyle}
            onClick={handleComment}
          >
            <CommentIcon />
          </IconButton>
        </span>

        <span className="">
          <IconButton
            variant="contained"
            size="small"
            sx={likeStyle}
            onClick={handleLike}
            disabled={liked}
          >
            <ThumbUpIcon sx={iconStyle} />
          </IconButton>
        </span>

        <span className="">
          <IconButton
            variant="contained"
            size="small"
            sx={dislikeStyle}
            onClick={handleDislike}
            disabled={disliked}
          >
            <ThumbDownIcon sx={iconStyle2} />
          </IconButton>
        </span>
      </div>
      <Dialog
        open={showConfirmationDialog}
        onClose={() => closeWindow()}
        maxWidth="md"
      >
        <DialogTitle sx={{ fontSize: "20px", color: "primary" }}>
          {isEnglish
            ? "Thanks for your feedback. Do you want to make any additional comments?"
            : "Gracias por tu feedback. ¿Quieres realizar algún comentario adicional?"}
        </DialogTitle>
        <DialogContent>
          <div className="flex flex-col bg-white border rounded ">
            <OutlinedInput
              type="text"
              placeholder={
                isEnglish
                  ? "Add your additional comments here (optional)"
                  : "Agrega aquí tus comentarios adicionales (opcional)"
              }
              value={comment}
              onChange={handleCommentChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  closeWindowAndSendFeedback();
                }
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          {isEnglish ? (
            <>
              <Button
                onClick={() => closeWindowAndSendFeedback()}
                color="primary"
              >
                Send
              </Button>
              <Button
                onClick={() => {
                  setLike(false);
                  setLiked(false);
                  setDisliked(false);
                  closeWindow();
                }}
                color="primary"
              >
                Cancel Feedback
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={() => closeWindowAndSendFeedback()}
                color="primary"
              >
                Enviar
              </Button>
              <Button
                onClick={() => {
                  setLike(false);
                  setLiked(false);
                  setDisliked(false);
                  closeWindow();
                }}
                color="primary"
              >
                Cancelar Feedback
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={showConfirmationDialog2}
        onClose={() => closeWindow()}
        maxWidth="md"
      >
        {isEnglish ? (
          <DialogTitle sx={{ fontSize: "20px", color: "primary" }}>
            Do you want to make any comments?
          </DialogTitle>
        ) : (
          <DialogTitle sx={{ fontSize: "20px", color: "primary" }}>
            ¿Quieres realizar algún comentario?
          </DialogTitle>
        )}
        <DialogContent>
          <div className="flex flex-col bg-white border rounded ">
            <OutlinedInput
              type="text"
              placeholder={
                isEnglish
                  ? "Add your comments here"
                  : "Añade aqui tus comentarios"
              }
              value={comment}
              onChange={handleCommentChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  closeWindowAndSendFeedback2();
                }
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          {isEnglish ? (
            <Button
              onClick={() => closeWindowAndSendFeedback2()}
              color="primary"
            >
              Send
            </Button>
          ) : (
            <Button
              onClick={() => closeWindowAndSendFeedback2()}
              color="primary"
            >
              Enviar
            </Button>
          )}
          {isEnglish ? (
            <Button
              onClick={() => {
                setLike(false);
                setLiked(false);
                setDisliked(false);
                closeWindow();
              }}
              color="primary"
            >
              Cancel Feedback
            </Button>
          ) : (
            <Button
              onClick={() => {
                setLike(false);
                setLiked(false);
                setDisliked(false);
                closeWindow();
              }}
              color="primary"
            >
              Cancelar Feedback
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Feedback;
